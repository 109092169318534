import React, { useEffect, useState } from "react";
import { Dialog, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import useFetchYoutubeVideos from "../../hooks/useFetchYoutubeVideos";
import useCreate from "../../hooks/useCreate";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";

const YoutubeApplicationModal = ({ data, isOpen, setIsOpen }) => {
  const [selectedVideos, setSelectedVideos] = useState([]);

  const { form_id, name, playlist_id, youtube_id } = data || {};

  const queryClient = useQueryClient();

  const [videosData, setVideosData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [nextPageToken, setNextPageToken] = useState();
  let playlistUrl = "";
  let submittedPlaylistId = "";
  try {
    playlistUrl = new URL(playlist_id);
    const searchParams = new URLSearchParams(playlistUrl.search);
    submittedPlaylistId = searchParams.get("list");
  } catch (err) {}

  const ytApiKey = "AIzaSyAp3BObS6AKRIdE74dpjFl82ru4Z1Qmrgo";
  const perPageLimit = 10;

  const fetchYoutubeReq = useFetchYoutubeVideos({
    method: "GET",
    onSuccess: (res) => {
      setVideosData([...videosData, ...res.items]);
      setTotalResults(res.pageInfo.totalResults);
      setNextPageToken(res.nextPageToken);
    },
  });

  const { setDataToServer: submitReq } = useCreate({
    url: "AdminPanel/addYoutubeVideos",
    onSuccess: () => {
      toast.success("Import request submitted!");
      setIsOpen(false);

      queryClient.invalidateQueries("AdminPanel/youtubeFormListing");
    },
  });

  const fetchVideosFromYoutube = () => {
    fetchYoutubeReq.mutate({
      key: ytApiKey,
      playlistId: submittedPlaylistId,
      part: "snippet",
      maxResults: perPageLimit,
    });
  };

  const fetchNextPage = () => {
    fetchYoutubeReq.mutate({
      key: "AIzaSyAp3BObS6AKRIdE74dpjFl82ru4Z1Qmrgo",
      playlistId: submittedPlaylistId,
      part: "snippet",
      maxResults: 10,
      pageToken: nextPageToken,
    });
  };

  const selectVideo = (el) => {
    const { snippet } = el || {};
    const { title, thumbnails, resourceId, description } = snippet || {};
    const { videoId } = resourceId || {};
    const { high } = thumbnails || {};
    const { url: thumbnailUrl } = high || {};

    const isPresent = selectedVideos.some((el) => {
      return el.video_id === videoId;
    });

    if (isPresent) {
      // Video present, remove it;
      const newArr = selectedVideos.filter((el) => {
        return el.video_id !== videoId;
      });

      setSelectedVideos(newArr);
    } else {
      // Video not present, add it
      let obj = {
        video_id: videoId,
        title: title,
        description: description,
        playlist_id: submittedPlaylistId,
        youtube_thumbnail: thumbnailUrl,
      };

      setSelectedVideos([...selectedVideos, obj]);
    }
  };

  const isVideoSelected = (el) => {
    const { snippet } = el || {};
    const { resourceId } = snippet || {};
    const { videoId } = resourceId || {};

    const isPresent = selectedVideos.some((el) => {
      return el.video_id === videoId;
    });

    return isPresent;
  };

  const importVideos = () => {
    submitReq.mutate({
      user_id: data.user_id,
      form_id: data.form_id,
      youtube_data: selectedVideos,
    });
  };

  return (
    <Dialog open={isOpen} fullScreen>
      <div className="bg-white p-10">
        <div className="flex items-center justify-between">
          <h1 className="my-5 font-bold text-xl">YouTube Shorts application</h1>
          <button onClick={() => setIsOpen(false)}>
            <Close />
          </button>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <div>
            <p className="font-bold">Application ID</p>
            <p>{form_id}</p>
          </div>
          <div>
            <p className="font-bold">User's name</p>
            <p>{name}</p>
          </div>
          <div>
            <p className="font-bold">YouTube ID</p>
            <p>@{youtube_id}</p>
          </div>
          <div>
            <p className="font-bold">Playlist link</p>
            <p>
              <a
                href={playlist_id}
                target="_blank"
                rel="noreferrer"
                className="text-blue-800"
              >
                {playlist_id}
              </a>
            </p>
          </div>
          {/* <div>
            <p className="font-bold">Status</p>
            <p>Pending</p>
          </div> */}
        </div>
        {videosData.length == 0 && (
          <button
            className="mx-auto mt-10 mb-10 bg-red-700 text-white px-5 py-1.5 rounded-md"
            onClick={fetchVideosFromYoutube}
          >
            Fetch videos
          </button>
        )}
        {videosData.length > 0 && (
          <h2 className="mt-6 mb-4 font-bold"> Fetched shorts:</h2>
        )}
        <div className="grid grid-cols-3 gap-6">
          {videosData.map((el, idx) => {
            const { snippet } = el || {};
            const { title, thumbnails, resourceId } = snippet || {};
            const { videoId } = resourceId || {};
            const { high } = thumbnails || {};
            const { url: thumbnailUrl } = high || {};

            return (
              <div className="border p-3">
                <div className="w-full border mx-auto bg-gray-50">
                  <img
                    src={thumbnailUrl}
                    className="w-full object-contain"
                    alt=""
                  />
                </div>
                <p className="mt-2 font-semibold">{title}</p>
                <div className="flex items-center justify-center mt-3 space-x-6">
                  <a
                    className="text-sm px-4 py-1.5 bg-red-200 rounded-md"
                    href={"https://www.youtube.com/watch?v=" + videoId}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View video
                  </a>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name=""
                      id={"check_" + videoId}
                      checked={isVideoSelected(el)}
                      onChange={() => selectVideo(el)}
                    />
                    <label
                      className="text-sm ml-2"
                      htmlFor={"check_" + videoId}
                    >
                      Select video
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {fetchYoutubeReq.isLoading && (
          <div className="grid place-items-center py-10">Loading...</div>
        )}

        {videosData.length < totalResults && (
          <div className="grid place-items-center">
            <button
              className="mx-auto mt-10 mb-10 bg-red-100 text-black px-5 py-1.5 rounded-md"
              onClick={fetchNextPage}
            >
              Fetch more
            </button>
          </div>
        )}

        <div className="grid place-items-center">
          <button
            disabled={selectedVideos.length <= 0 || submitReq.isLoading}
            className="mx-auto mt-10 mb-10 bg-red-700 text-white px-5 py-1.5 rounded-md disabled:opacity-60"
            onClick={importVideos}
          >
            Import videos
          </button>
        </div>

        {submitReq.isLoading && (
          <div className="grid place-items-center py-5">Submitting...</div>
        )}
      </div>
    </Dialog>
  );
};

export default YoutubeApplicationModal;
