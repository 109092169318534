import React, { useState } from "react";
import Layout from "../../components/layout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import useRead from "../../hooks/useRead";
import YoutubeApplicationModal from "../../components/modals/YoutubeApplicationModal";
import ReactPaginate from "react-paginate";
import { Toaster } from "react-hot-toast";

const statusValues = {
  1: "Done",
  2: "In process",
  3: "Pending",
};

const YoutubeApplications = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [details, setDetails] = useState();

  const { paramsObject, setGetListParams, data } = useRead({
    url: "AdminPanel/youtubeFormListing",
    initialData: {
      pageNo: 0,
      limit: 10,
      search_query: "",
    },
  });

  const applications = data?.data;

  const handlePageClick = (val) => {
    setGetListParams({
      ...paramsObject,
      pageNo: val.selected,
    });
  };

  return (
    <Layout>
      <Toaster />
      <h1 className="mt-5 mb-2 text-xl font-bold">
        YouTube Shorts applications
      </h1>

      {showDetailsModal && (
        <YoutubeApplicationModal
          data={details}
          isOpen={showDetailsModal}
          setIsOpen={setShowDetailsModal}
        />
      )}

      <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8 mb-10">
        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
          <thead>
            <tr className="text-left">
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Sr.no
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Name
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Youtube ID
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Playlist Link
              </th>
              {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Submitted on
              </th> */}
              <th className=" bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                Status
              </th>
              <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                View application
              </th>
            </tr>
          </thead>
          <tbody>
            {applications?.data?.map((el, idx) => {
              const {
                id,
                name,
                youtube_id,
                playlist_id,
                form_status,
                submitted_on,
              } = el;
              return (
                <tr key={idx}>
                  <td>
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      {paramsObject.pageNo * paramsObject.limit + (idx + 1)}
                    </span>
                  </td>
                  <td>
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      {name}
                    </span>
                  </td>
                  <td>
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      {youtube_id}
                    </span>
                  </td>
                  <td>
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      {playlist_id}
                    </span>
                  </td>
                  <td className=" text-center">
                    {/* <span className="text-gray-700 px-6 py-3 flex items-center text-sm w-[20%]"> */}
                    {statusValues[form_status]}
                    {/* </span> */}
                  </td>
                  <td className="grid place-items-center">
                    {form_status !== "2" && (
                      <button
                        to={"/outsourced-yt-application/" + id}
                        onClick={() => {
                          setDetails(el);
                          setShowDetailsModal(true);
                        }}
                        className="px-4 py-2 rounded bg-gray-300 mx-auto my-1.5"
                      >
                        <RemoveRedEyeIcon /> View
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.isLoading && (
          <div className="grid place-items-center py-10">Loading...</div>
        )}
      </div>

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={Math.ceil(
          applications?.total_count / parseInt(applications?.per_page)
        )}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName="flex items-center justify-center space-x-4"
        pageLinkClassName="pageNumber"
        previousLinkClassName="pageNumber"
        nextLinkClassName="pageNumber"
        activeLinkClassName="selectedPageNumber"
        disabledClassName="lastPage"
        disabledLinkClassName="lastPage"
      />

      <br />
      <br />
    </Layout>
  );
};

export default YoutubeApplications;
