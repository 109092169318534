import React, { useState } from "react";
import Layout from "../../components/layout";
import { Modal } from "@mui/material";

const YoutubeSingleApplication = ({ data, isOpen, setIsOpen }) => {
  const [selectedVideos, setSelectedVideos] = useState([]);

  return (
    <Modal open={isOpen}>
      <div>
        <h1 className="my-5 font-bold text-xl">YouTube Shorts application</h1>
        <div className="grid grid-cols-3 gap-6">
          <div>
            <p className="font-bold">Application ID</p>
            <p>78</p>
          </div>
          <div>
            <p className="font-bold">User's name</p>
            <p>Jane Doe</p>
          </div>
          <div>
            <p className="font-bold">YouTube ID</p>
            <p>@janedoe</p>
          </div>
          <div>
            <p className="font-bold">Submitted on</p>
            <p>14th September 2024</p>
          </div>
          <div>
            <p className="font-bold">Status</p>
            <p>Pending</p>
          </div>
        </div>
        <button className="mx-auto mt-10 mb-10 bg-red-700 text-white px-5 py-1.5 rounded-md">
          Fetch videos
        </button>

        <h2 className="font-medium mb-4"> Fetched shorts:</h2>
        <div className="grid grid-cols-3 gap-6">
          <div className="border p-3">
            <div className="w-20 h-32 border mx-auto bg-yellow-50"></div>
            <div className="flex items-center justify-center mt-3 space-x-6">
              <button className="text-sm px-4 py-1.5 bg-red-200 rounded-md">
                View video
              </button>
              <div className="flex items-center">
                <input type="checkbox" name="" id="" />
                <label className="text-sm ml-2" htmlFor="">
                  Select video
                </label>
              </div>
            </div>
          </div>

          <div className="border p-3">
            <div className="w-20 h-32 border mx-auto bg-yellow-50"></div>
            <div className="flex items-center justify-center mt-3 space-x-6">
              <button className="text-sm px-4 py-1.5 bg-red-200 rounded-md">
                View video
              </button>
              <div className="flex items-center">
                <input type="checkbox" name="" id="" />
                <label className="text-sm ml-2" htmlFor="">
                  Select video
                </label>
              </div>
            </div>
          </div>

          <div className="border p-3">
            <div className="w-20 h-32 border mx-auto bg-yellow-50"></div>
            <div className="flex items-center justify-center mt-3 space-x-6">
              <button className="text-sm px-4 py-1.5 bg-red-200 rounded-md">
                View video
              </button>
              <div className="flex items-center">
                <input type="checkbox" name="" id="" />
                <label className="text-sm ml-2" htmlFor="">
                  Select video
                </label>
              </div>
            </div>
          </div>

          <div className="border p-3">
            <div className="w-20 h-32 border mx-auto bg-yellow-50"></div>
            <div className="flex items-center justify-center mt-3 space-x-6">
              <button className="text-sm px-4 py-1.5 bg-red-200 rounded-md">
                View video
              </button>
              <div className="flex items-center">
                <input type="checkbox" name="" id="" />
                <label className="text-sm ml-2" htmlFor="">
                  Select video
                </label>
              </div>
            </div>
          </div>

          <div className="border p-3">
            <div className="w-20 h-32 border mx-auto bg-yellow-50"></div>
            <div className="flex items-center justify-center mt-3 space-x-6">
              <button className="text-sm px-4 py-1.5 bg-red-200 rounded-md">
                View video
              </button>
              <div className="flex items-center">
                <input type="checkbox" name="" id="" />
                <label className="text-sm ml-2" htmlFor="">
                  Select video
                </label>
              </div>
            </div>
          </div>
          
        </div>

        <div className="grid place-items-center">
          <button className="mx-auto mt-10 mb-10 bg-red-700 text-white px-5 py-1.5 rounded-md">
            Import videos
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default YoutubeSingleApplication;
